@use "sass:map";
@use "@angular/material" as mat;

gt-main-nav,
gt-profile,
gt-settings {
  @include mat.list-typography($nav-list-typography);
}

.sub-items {
  .mdc-list-item__primary-text {
    color: mat.get-color-from-palette(mat.$gray-palette, 600);
    word-wrap: normal;
    white-space: normal;
  }
}

.active-route {
  background-color: mat.get-color-from-palette(mat.$gray-palette, 100);

  .mdc-list-item__primary-text {
    color: mat.get-color-from-palette($amaranth-palette, 500);
  }
}

.active-route:hover {
  background-color: mat.get-color-from-palette(mat.$gray-palette, 100);
}
